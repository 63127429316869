import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';
import './css/maincarousel.css';

/*const items = [
    {
        src: '/img/slides01.jpg',
        id: 1,
        alt: 'Slide 1',
        caption: 'Slide 1',
        description: 'This is slide 1'
        url: '/trainers'
    },
    {
        src: '/img/slides02.jpg',
        id: 2,
        alt: 'Slide 2',
        caption: 'Slide 2',
        description: 'This is slide 2'
        url: '/courses'
    },
    {
        src: '/img/slides03.jpg',
        id: 3,
        alt: 'Slide 3',
        caption: 'Slide 3',
        description: 'This is slide 3'
        url: '/paths'
    }
];*/

class MainCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            error: null,
            isLoaded: false,
            items: []
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        //fetch("http://192.168.1.9:8181/api/v1/slides", {
        fetch("/api/v1/slides", {
            //method: "POST", // *GET, POST, PUT, DELETE, etc.
            //mode: "cors", // no-cors, cors, *same-origin
            //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Accept": "application/json",
                // "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            //redirect: "follow", // manual, *follow, error
            //referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header

        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        const { items } = this.state;
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        const { items } = this.state;
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex, error, isLoaded, items } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            const slides = items.map((item) => {
                return (
                    <CarouselItem
                        className="custom-tag"
                        tag="div"
                        key={item.id}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                    >
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt}
                        />
                        <div className="carousel-caption text-left">
                            <h2 className="text-white">{item.caption}</h2>
                            <p className="text-light">{item.description}</p>
                            <a href={item.url} role="button" className="btn btn-lg btn-primary">Learn more</a>
                        </div>
                    </CarouselItem>
                );
            });

            return (
                <div>
                    <style>
                        {
                            `.custom-tag {
                max-width: 100%;
                height: 500px;
                background: black;
              }`
                        }
                    </style>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                    >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </div>
            );
        }
    }
}

export default MainCarousel;
