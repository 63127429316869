import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
import MainCarousel from "./components/MainCarousel";
import SlickPathsDisplay from './components/SlickPathsDisplay';

class App extends Component {
  render() {
    return (
      //<div className="TrainersApp">
        //<header className="TrainersApp-header">
      <div className="App">
        <SlickPathsDisplay />
      </div>
        //</header>
      //</div>
    );
  }
}

export default App;
