import React, { Component } from 'react';
import Slider from "react-slick";
import SlickPopover from "./SlickPopover";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/slickpathsdisplay.css';

class SlickPathsDisplay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            referrerName: document.getElementById('referrer_origin').getAttribute('data-name'),
            referrerCurrency: document.getElementById('referrer_origin').getAttribute('data-currency'),
            referrerConversion: document.getElementById('referrer_origin').getAttribute('data-conversion'),
            referrerDiscount: document.getElementById('referrer_origin').getAttribute('data-rate'),
            width: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidMount() {
        let filteredCourses = [];

        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
        //fetch("http://192.168.1.9:8181/api/v1/courses", {
        fetch("/api/v1/courses", {
            //method: "POST", // *GET, POST, PUT, DELETE, etc.
            //mode: "cors", // no-cors, cors, *same-origin
            //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Accept": "application/json",
                // "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            //redirect: "follow", // manual, *follow, error
            //referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header

        })
            .then(res => res.json())
            .then(
                (result) => {
                    for (let i = 0, j = result.length; i < j; i ++) {
                        if (result[i].course_cat_num.toUpperCase().includes('CORE-')) {
                            filteredCourses.push(result[i]);
                        } else if (result[i].course_cat_num.toUpperCase().includes('JUMP-')) {
                            filteredCourses.push(result[i]);
                        }
                    }

                    this.setState({
                        isLoaded: true,
                        items: filteredCourses.reverse()
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    cartAddClick(id) {
        let formData = new FormData();

        formData.append('id', id);

        //fetch("http://192.168.1.13:8181/cart/add", {
        fetch("/cart/add", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            //mode: "cors", // no-cors, cors, *same-origin
            //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            headers: {
                // "Accept": "application/json",
                // "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            //redirect: "follow", // manual, *follow, error
            //referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
            body: formData
        })
        //.then(response => console.log('Success:', response))
            .then(function(response) {
                if (response.status === 200) {
                    return response.text();
                } else if (response.status === 400) {
                    return "ERROR! Cart item was NOT added!";
                }
            })
            .then((text)=>{ alert(text) })
            .catch(error => console.error('Error:', error));
    }

    render() {
        const {
            width,
            error,
            isLoaded,
            items,
            referrerName,
            referrerCurrency,
            referrerConversion,
            referrerDiscount } = this.state;

        let settings = {
            arrows: true,
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'slides',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            let isMobile = width <= 500;

            return (
                <div className="container">
                    <h2>Our Courses</h2>
                    <Slider {...settings}>
                        {items.map(item => (
                            <div>
                                <div className="card mb-4 box-shadow" id={'PopoverFocus' + item.id}>
                                    <img className="card-img-top" src={"/img/" + item.image} alt="Course logo" />
                                    <div className="card-header">
                                        <h4 className="my-0 font-weight-normal">{item.title}</h4>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title pricing-card-title">
                                            {item.price > 1 && referrerName !== 'none' && <small className="text-muted">{Math.ceil((item.price * referrerDiscount) * referrerConversion)} {referrerCurrency}</small>}
                                            {item.price > 1 && referrerName === 'none' && <small className="text-red font-weight-bold">50% OFF!<br /><s className="text-muted text-sm">{Math.ceil(item.price * referrerConversion * 2)} {referrerCurrency}</s></small>}
                                            {item.price > 1 && referrerName === 'none' && <p className="text-red font-weight-bold">{item.price * referrerConversion} {referrerCurrency}</p>}
                                            {item.price === 1 && <p className="text-red font-weight-bold"><br /><br />NEW COURSE!</p>}
                                            {item.price === 0 && <p className="text-red font-weight-bold"><br /><br />FREE!</p>}
                                        </h3>
                                        <ul className="list-unstyled mt-3 mb-4">
                                            <li className="list-unstyled-lead">{item.lead}</li>
                                        </ul>
                                        {item.price > 1 && <p><a href={"/courses/scheduled/" + item.id} className="btn btn-lg btn-block btn-primary">Buy now</a></p>}
                                        {item.price === 1 && <p><a href={"/courses/scheduled/" + item.id} className="btn btn-lg btn-block btn-primary">Learn more</a></p>}
                                        {item.price === 0 && <p><a href={"/courses/scheduled/" + item.id} className="btn btn-lg btn-block btn-primary">Enroll now</a></p>}
                                    </div>
                                </div>
                                { !isMobile && <SlickPopover title={item.title} description={item.description} idNumber={item.id}/> }
                            </div>
                        ))}
                    </Slider>
                </div>
            );
        }
    }
}

export default SlickPathsDisplay;
