import React, { Component } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Markup } from 'interweave';

class SlickPopover extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        return (
            <div>
                <UncontrolledPopover trigger="hover" placement="auto" target={'PopoverFocus' + this.props.idNumber}>
                    <PopoverHeader>{this.props.title}</PopoverHeader>
                    <PopoverBody>
                        <Markup content={this.props.description}/>
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }
}

export default SlickPopover;